.sd-element[data-name="TripData"] .sd-title span,
.sd-element[data-name="Satisfaction"] .sd-title span,
.sd-element[data-name="SurveyQuestions"] .sd-title span,
.sd-element[data-name="DemographicInformation"] .sd-title span,
.sd-element[data-name="DataNotEnteredByAngler"] .sd-title span,
.sd-element[data-name="CatchData"] .sd-title span,
.sd-element[data-name="SpeciesDetails"] .sd-title span {
  font-weight: bold;
  font-size: 20px;
}
