/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#dt/jszip-3.10.1/dt-2.0.8/b-3.0.2/b-colvis-3.0.2/b-html5-3.0.2/sb-1.7.1
 *
 * Included libraries:
 *  JSZip 3.10.1, DataTables 2.0.8, Buttons 3.0.2, Column visibility 3.0.2, HTML5 export 3.0.2, SearchBuilder 1.7.1
 */

.ciras-datatable {
  width: 100%;
  padding: 20px;
}

table.dataTable {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.dt-length select {
  margin-right: 10px;
}

.dt-search,
.dt-buttons {
  text-align: right;
}

:root {
  --dt-row-selected: 13, 110, 253;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
  --dt-row-stripe: 0, 0, 0;
  --dt-row-hover: 0, 0, 0;
  --dt-column-ordering: 0, 0, 0;
  --dt-html-background: white;
}
:root.dark {
  --dt-html-background: rgb(33, 37, 41);
}
table.dataTable td.dt-control {
  text-align: center;
  cursor: pointer;
}
table.dataTable td.dt-control:before {
  display: inline-block;
  box-sizing: border-box;
  content: "";
  border-top: 5px solid transparent;
  border-left: 10px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid transparent;
  border-right: 0px solid transparent;
}
table.dataTable tr.dt-hasChild td.dt-control:before {
  border-top: 10px solid rgba(0, 0, 0, 0.5);
  border-left: 5px solid transparent;
  border-bottom: 0px solid transparent;
  border-right: 5px solid transparent;
}
html.dark table.dataTable td.dt-control:before,
:root[data-bs-theme="dark"] table.dataTable td.dt-control:before {
  border-left-color: rgba(255, 255, 255, 0.5);
}
html.dark table.dataTable tr.dt-hasChild td.dt-control:before,
:root[data-bs-theme="dark"]
  table.dataTable
  tr.dt-hasChild
  td.dt-control:before {
  border-top-color: rgba(255, 255, 255, 0.5);
  border-left-color: transparent;
}
div.dt-scroll-body thead tr,
div.dt-scroll-body tfoot tr {
  height: 0;
}
div.dt-scroll-body thead tr th,
div.dt-scroll-body thead tr td,
div.dt-scroll-body tfoot tr th,
div.dt-scroll-body tfoot tr td {
  height: 0 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
}
div.dt-scroll-body thead tr th div.dt-scroll-sizing,
div.dt-scroll-body thead tr td div.dt-scroll-sizing,
div.dt-scroll-body tfoot tr th div.dt-scroll-sizing,
div.dt-scroll-body tfoot tr td div.dt-scroll-sizing {
  height: 0 !important;
  overflow: hidden !important;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:before,
table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before {
  position: absolute;
  display: block;
  bottom: 50%;
  content: "▲";
  content: "▲"/"";
}
table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after,
table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  position: absolute;
  display: block;
  top: 50%;
  content: "▼";
  content: "▼"/"";
}
table.dataTable thead > tr > th.dt-orderable-asc,
table.dataTable thead > tr > th.dt-orderable-desc,
table.dataTable thead > tr > th.dt-ordering-asc,
table.dataTable thead > tr > th.dt-ordering-desc,
table.dataTable thead > tr > td.dt-orderable-asc,
table.dataTable thead > tr > td.dt-orderable-desc,
table.dataTable thead > tr > td.dt-ordering-asc,
table.dataTable thead > tr > td.dt-ordering-desc {
  position: relative;
  padding-right: 30px;
}
table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order,
table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order,
table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order,
table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order,
table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order,
table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  width: 12px;
}
table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:before,
table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:after,
table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:before,
table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after,
table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:after,
table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:before,
table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  left: 0;
  opacity: 0.125;
  line-height: 9px;
  font-size: 0.8em;
}
table.dataTable thead > tr > th.dt-orderable-asc,
table.dataTable thead > tr > th.dt-orderable-desc,
table.dataTable thead > tr > td.dt-orderable-asc,
table.dataTable thead > tr > td.dt-orderable-desc {
  cursor: pointer;
}
table.dataTable thead > tr > th.dt-orderable-asc:hover,
table.dataTable thead > tr > th.dt-orderable-desc:hover,
table.dataTable thead > tr > td.dt-orderable-asc:hover,
table.dataTable thead > tr > td.dt-orderable-desc:hover {
  outline: 2px solid rgba(0, 0, 0, 0.05);
  outline-offset: -2px;
}
table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  opacity: 0.6;
}
table.dataTable
  thead
  > tr
  > th.sorting_desc_disabled
  span.dt-column-order:after,
table.dataTable
  thead
  > tr
  > th.sorting_asc_disabled
  span.dt-column-order:before,
table.dataTable
  thead
  > tr
  > td.sorting_desc_disabled
  span.dt-column-order:after,
table.dataTable
  thead
  > tr
  > td.sorting_asc_disabled
  span.dt-column-order:before {
  display: none;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
div.dt-scroll-body > table.dataTable > thead > tr > th,
div.dt-scroll-body > table.dataTable > thead > tr > td {
  overflow: hidden;
}
:root.dark table.dataTable thead > tr > th.dt-orderable-asc:hover,
:root.dark table.dataTable thead > tr > th.dt-orderable-desc:hover,
:root.dark table.dataTable thead > tr > td.dt-orderable-asc:hover,
:root.dark table.dataTable thead > tr > td.dt-orderable-desc:hover,
:root[data-bs-theme="dark"]
  table.dataTable
  thead
  > tr
  > th.dt-orderable-asc:hover,
:root[data-bs-theme="dark"]
  table.dataTable
  thead
  > tr
  > th.dt-orderable-desc:hover,
:root[data-bs-theme="dark"]
  table.dataTable
  thead
  > tr
  > td.dt-orderable-asc:hover,
:root[data-bs-theme="dark"]
  table.dataTable
  thead
  > tr
  > td.dt-orderable-desc:hover {
  outline: 2px solid rgba(255, 255, 255, 0.05);
}
div.dt-processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -22px;
  text-align: center;
  padding: 2px;
  z-index: 10;
}
div.dt-processing > div:last-child {
  position: relative;
  width: 80px;
  height: 15px;
  margin: 1em auto;
}
div.dt-processing > div:last-child > div {
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(13, 110, 253);
  background: rgb(var(--dt-row-selected));
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
div.dt-processing > div:last-child > div:nth-child(1) {
  left: 8px;
  animation: datatables-loader-1 0.6s infinite;
}
div.dt-processing > div:last-child > div:nth-child(2) {
  left: 8px;
  animation: datatables-loader-2 0.6s infinite;
}
div.dt-processing > div:last-child > div:nth-child(3) {
  left: 32px;
  animation: datatables-loader-2 0.6s infinite;
}
div.dt-processing > div:last-child > div:nth-child(4) {
  left: 56px;
  animation: datatables-loader-3 0.6s infinite;
}
@keyframes datatables-loader-1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes datatables-loader-3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes datatables-loader-2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}
table.dataTable th,
table.dataTable td {
  box-sizing: border-box;
}
table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left;
}
table.dataTable th.dt-center,
table.dataTable td.dt-center {
  text-align: center;
}
table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right;
}
table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify;
}
table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap;
}
table.dataTable th.dt-empty,
table.dataTable td.dt-empty {
  text-align: center;
  vertical-align: top;
}
table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
  text-align: right;
}
table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
  text-align: left;
}
table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}
table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}
table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}
table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}
table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}
table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left;
}
table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center;
}
table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right;
}
table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}
table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}
table.dataTable {
  width: 100%;
  margin: 0 auto;
  border-spacing: 0;
}
table.dataTable thead th,
table.dataTable tfoot th {
  font-weight: bold;
}
table.dataTable > thead > tr > th,
table.dataTable > thead > tr > td {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
table.dataTable > thead > tr > th:active,
table.dataTable > thead > tr > td:active {
  outline: none;
}
table.dataTable > tfoot > tr > th,
table.dataTable > tfoot > tr > td {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding: 10px 10px 6px 10px;
}
table.dataTable > tbody > tr {
  background-color: transparent;
}
table.dataTable > tbody > tr:first-child > * {
  border-top: none;
}
table.dataTable > tbody > tr:last-child > * {
  border-bottom: none;
}
table.dataTable > tbody > tr.selected > * {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.9);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.9);
  color: rgb(255, 255, 255);
  color: rgb(var(--dt-row-selected-text));
}
table.dataTable > tbody > tr.selected a {
  color: rgb(9, 10, 11);
  color: rgb(var(--dt-row-selected-link));
}
table.dataTable > tbody > tr > th,
table.dataTable > tbody > tr > td {
  padding: 8px 10px;
}
table.dataTable.row-border > tbody > tr > *,
table.dataTable.display > tbody > tr > * {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
table.dataTable.row-border > tbody > tr:first-child > *,
table.dataTable.display > tbody > tr:first-child > * {
  border-top: none;
}
table.dataTable.row-border > tbody > tr.selected + tr.selected > td,
table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: rgba(13, 110, 253, 0.65);
  border-top-color: rgba(var(--dt-row-selected), 0.65);
}
table.dataTable.cell-border > tbody > tr > * {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}
table.dataTable.cell-border > tbody > tr > *:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}
table.dataTable.cell-border > tbody > tr:first-child > * {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}
table.dataTable.stripe > tbody > tr:nth-child(odd) > *,
table.dataTable.display > tbody > tr:nth-child(odd) > * {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.023);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), 0.023);
}
table.dataTable.stripe > tbody > tr:nth-child(odd).selected > *,
table.dataTable.display > tbody > tr:nth-child(odd).selected > * {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.923);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.923);
}
table.dataTable.hover > tbody > tr:hover > *,
table.dataTable.display > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.035);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.035);
}
table.dataTable.hover > tbody > tr.selected:hover > *,
table.dataTable.display > tbody > tr.selected:hover > * {
  box-shadow: inset 0 0 0 9999px #0d6efd !important;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 1) !important;
}
table.dataTable.order-column > tbody tr > .sorting_1,
table.dataTable.order-column > tbody tr > .sorting_2,
table.dataTable.order-column > tbody tr > .sorting_3,
table.dataTable.display > tbody tr > .sorting_1,
table.dataTable.display > tbody tr > .sorting_2,
table.dataTable.display > tbody tr > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.019);
}
table.dataTable.order-column > tbody tr.selected > .sorting_1,
table.dataTable.order-column > tbody tr.selected > .sorting_2,
table.dataTable.order-column > tbody tr.selected > .sorting_3,
table.dataTable.display > tbody tr.selected > .sorting_1,
table.dataTable.display > tbody tr.selected > .sorting_2,
table.dataTable.display > tbody tr.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
}
table.dataTable.display > tbody > tr:nth-child(odd) > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr:nth-child(odd) > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.054);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.054);
}
table.dataTable.display > tbody > tr:nth-child(odd) > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr:nth-child(odd) > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.047);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.047);
}
table.dataTable.display > tbody > tr:nth-child(odd) > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr:nth-child(odd) > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.039);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.039);
}
table.dataTable.display > tbody > tr:nth-child(odd).selected > .sorting_1,
table.dataTable.order-column.stripe
  > tbody
  > tr:nth-child(odd).selected
  > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.954);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.954);
}
table.dataTable.display > tbody > tr:nth-child(odd).selected > .sorting_2,
table.dataTable.order-column.stripe
  > tbody
  > tr:nth-child(odd).selected
  > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.947);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.947);
}
table.dataTable.display > tbody > tr:nth-child(odd).selected > .sorting_3,
table.dataTable.order-column.stripe
  > tbody
  > tr:nth-child(odd).selected
  > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.939);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.939);
}
table.dataTable.display > tbody > tr.even > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.even > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.019);
}
table.dataTable.display > tbody > tr.even > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr.even > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.011);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.011);
}
table.dataTable.display > tbody > tr.even > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr.even > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.003);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), 0.003);
}
table.dataTable.display > tbody > tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.919);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.919);
}
table.dataTable.display > tbody > tr.even.selected > .sorting_2,
table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.911);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.911);
}
table.dataTable.display > tbody > tr.even.selected > .sorting_3,
table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.903);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.903);
}
table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.082);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.082);
}
table.dataTable.display tbody tr:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.074);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.074);
}
table.dataTable.display tbody tr:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.062);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), 0.062);
}
table.dataTable.display tbody tr:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.982);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.982);
}
table.dataTable.display tbody tr:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.974);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.974);
}
table.dataTable.display tbody tr:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px rgba(13, 110, 253, 0.962);
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 0.962);
}
table.dataTable.compact thead th,
table.dataTable.compact thead td,
table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td,
table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
  padding: 4px;
}
div.dt-container {
  position: relative;
  clear: both;
}
div.dt-container div.dt-layout-row {
  display: table;
  clear: both;
  width: 100%;
}
div.dt-container div.dt-layout-row.dt-layout-table {
  display: block;
}
div.dt-container div.dt-layout-row.dt-layout-table div.dt-layout-cell {
  display: block;
}
div.dt-container div.dt-layout-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 5px 0;
}
div.dt-container div.dt-layout-cell.dt-full {
  text-align: center;
}
div.dt-container div.dt-layout-cell.dt-start {
  text-align: left;
}
div.dt-container div.dt-layout-cell.dt-end {
  text-align: right;
}
div.dt-container div.dt-layout-cell:empty {
  display: none;
}
div.dt-container .dt-search input {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  color: inherit;
  margin-left: 3px;
}
div.dt-container .dt-input {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  color: inherit;
}
div.dt-container select.dt-input {
  padding: 4px;
}
div.dt-container .dt-paging .dt-paging-button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: inherit !important;
  border: 1px solid transparent;
  border-radius: 2px;
  background: transparent;
}
div.dt-container .dt-paging .dt-paging-button.current,
div.dt-container .dt-paging .dt-paging-button.current:hover {
  color: inherit !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.05);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(230, 230, 230, 0.05)),
    color-stop(100%, rgba(0, 0, 0, 0.05))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(230, 230, 230, 0.05) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
  background: -moz-linear-gradient(
    top,
    rgba(230, 230, 230, 0.05) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(230, 230, 230, 0.05) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(230, 230, 230, 0.05) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(230, 230, 230, 0.05) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
}
div.dt-container .dt-paging .dt-paging-button.disabled,
div.dt-container .dt-paging .dt-paging-button.disabled:hover,
div.dt-container .dt-paging .dt-paging-button.disabled:active {
  cursor: default;
  color: rgba(0, 0, 0, 0.5) !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}
div.dt-container .dt-paging .dt-paging-button:hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #111;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #585858),
    color-stop(100%, #111)
  );
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
}
div.dt-container .dt-paging .dt-paging-button:active {
  outline: none;
  background-color: #0c0c0c;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #2b2b2b),
    color-stop(100%, #0c0c0c)
  );
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  box-shadow: inset 0 0 3px #111;
}
div.dt-container .dt-paging .ellipsis {
  padding: 0 1em;
}
div.dt-container .dt-length,
div.dt-container .dt-search,
div.dt-container .dt-info,
div.dt-container .dt-processing,
div.dt-container .dt-paging {
  color: inherit;
}
div.dt-container .dataTables_scroll {
  clear: both;
}
div.dt-container .dataTables_scroll div.dt-scroll-body {
  -webkit-overflow-scrolling: touch;
}
div.dt-container
  .dataTables_scroll
  div.dt-scroll-body
  > table
  > thead
  > tr
  > th,
div.dt-container
  .dataTables_scroll
  div.dt-scroll-body
  > table
  > thead
  > tr
  > td,
div.dt-container
  .dataTables_scroll
  div.dt-scroll-body
  > table
  > tbody
  > tr
  > th,
div.dt-container
  .dataTables_scroll
  div.dt-scroll-body
  > table
  > tbody
  > tr
  > td {
  vertical-align: middle;
}
div.dt-container
  .dataTables_scroll
  div.dt-scroll-body
  > table
  > thead
  > tr
  > th
  > div.dataTables_sizing,
div.dt-container
  .dataTables_scroll
  div.dt-scroll-body
  > table
  > thead
  > tr
  > td
  > div.dataTables_sizing,
div.dt-container
  .dataTables_scroll
  div.dt-scroll-body
  > table
  > tbody
  > tr
  > th
  > div.dataTables_sizing,
div.dt-container
  .dataTables_scroll
  div.dt-scroll-body
  > table
  > tbody
  > tr
  > td
  > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}
div.dt-container.dt-empty-footer tbody > tr:last-child > * {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
div.dt-container.dt-empty-footer .dt-scroll-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
div.dt-container.dt-empty-footer .dt-scroll-body tbody > tr:last-child > * {
  border-bottom: none;
}
@media screen and (max-width: 767px) {
  div.dt-container div.dt-layout-row {
    display: block;
  }
  div.dt-container div.dt-layout-cell {
    display: block;
  }
  div.dt-container div.dt-layout-cell.dt-full,
  div.dt-container div.dt-layout-cell.dt-start,
  div.dt-container div.dt-layout-cell.dt-end {
    text-align: center;
  }
}
@media screen and (max-width: 640px) {
  .dt-container .dt-length,
  .dt-container .dt-search {
    float: none;
    text-align: center;
  }
  .dt-container .dt-search {
    margin-top: 0.5em;
  }
}
html.dark {
  --dt-row-hover: 255, 255, 255;
  --dt-row-stripe: 255, 255, 255;
  --dt-column-ordering: 255, 255, 255;
}
html.dark table.dataTable > thead > tr > th,
html.dark table.dataTable > thead > tr > td {
  border-bottom: 1px solid rgb(89, 91, 94);
}
html.dark table.dataTable > thead > tr > th:active,
html.dark table.dataTable > thead > tr > td:active {
  outline: none;
}
html.dark table.dataTable > tfoot > tr > th,
html.dark table.dataTable > tfoot > tr > td {
  border-top: 1px solid rgb(89, 91, 94);
}
html.dark table.dataTable.row-border > tbody > tr > *,
html.dark table.dataTable.display > tbody > tr > * {
  border-top: 1px solid rgb(64, 67, 70);
}
html.dark table.dataTable.row-border > tbody > tr:first-child > *,
html.dark table.dataTable.display > tbody > tr:first-child > * {
  border-top: none;
}
html.dark table.dataTable.row-border > tbody > tr.selected + tr.selected > td,
html.dark table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: rgba(13, 110, 253, 0.65);
  border-top-color: rgba(var(--dt-row-selected), 0.65);
}
html.dark table.dataTable.cell-border > tbody > tr > th,
html.dark table.dataTable.cell-border > tbody > tr > td {
  border-top: 1px solid rgb(64, 67, 70);
  border-right: 1px solid rgb(64, 67, 70);
}
html.dark table.dataTable.cell-border > tbody > tr > th:first-child,
html.dark table.dataTable.cell-border > tbody > tr > td:first-child {
  border-left: 1px solid rgb(64, 67, 70);
}
html.dark .dt-container.dt-empty-footer table.dataTable {
  border-bottom: 1px solid rgb(89, 91, 94);
}
html.dark .dt-container .dt-search input,
html.dark .dt-container .dt-length select {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: var(--dt-html-background);
}
html.dark .dt-container .dt-paging .dt-paging-button.current,
html.dark .dt-container .dt-paging .dt-paging-button.current:hover {
  border: 1px solid rgb(89, 91, 94);
  background: rgba(255, 255, 255, 0.15);
}
html.dark .dt-container .dt-paging .dt-paging-button.disabled,
html.dark .dt-container .dt-paging .dt-paging-button.disabled:hover,
html.dark .dt-container .dt-paging .dt-paging-button.disabled:active {
  color: #666 !important;
}
html.dark .dt-container .dt-paging .dt-paging-button:hover {
  border: 1px solid rgb(53, 53, 53);
  background: rgb(53, 53, 53);
}
html.dark .dt-container .dt-paging .dt-paging-button:active {
  background: #3a3a3a;
}
*[dir="rtl"] table.dataTable thead th,
*[dir="rtl"] table.dataTable thead td,
*[dir="rtl"] table.dataTable tfoot th,
*[dir="rtl"] table.dataTable tfoot td {
  text-align: right;
}
*[dir="rtl"] table.dataTable th.dt-type-numeric,
*[dir="rtl"] table.dataTable th.dt-type-date,
*[dir="rtl"] table.dataTable td.dt-type-numeric,
*[dir="rtl"] table.dataTable td.dt-type-date {
  text-align: left;
}
*[dir="rtl"] div.dt-container div.dt-layout-cell.dt-start {
  text-align: right;
}
*[dir="rtl"] div.dt-container div.dt-layout-cell.dt-end {
  text-align: left;
}
*[dir="rtl"] div.dt-container div.dt-search input {
  margin: 0 3px 0 0;
}

@keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes dtb-spinner {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes dtb-spinner {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dtb-spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes dtb-spinner {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
div.dataTables_wrapper {
  position: relative;
}
div.dt-buttons {
  position: initial;
}
div.dt-buttons .dt-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border-radius: 0.75em;
  box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.8);
  text-align: center;
  z-index: 2003;
  overflow: hidden;
}
div.dt-button-info h2 {
  padding: 2rem 2rem 1rem 2rem;
  margin: 0;
  font-weight: normal;
}
div.dt-button-info > div {
  padding: 1em 2em 2em 2em;
}
div.dtb-popover-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 2003;
}
button.dtb-hide-drop {
  display: none !important;
}
div.dt-button-collection-title {
  text-align: center;
  padding: 0.3em 0.5em 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.dt-button-collection-title:empty {
  display: none;
}
span.dt-button-spacer {
  display: inline-block;
  margin: 0.5em;
  white-space: nowrap;
}
span.dt-button-spacer.bar {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  vertical-align: middle;
  padding-left: 0.5em;
}
span.dt-button-spacer.bar:empty {
  height: 1em;
  width: 1px;
  padding-left: 0;
}
div.dt-button-collection .dt-button-active {
  padding-right: 3em;
}
div.dt-button-collection .dt-button-active:after {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 1em;
  display: inline-block;
  content: "✓";
  color: inherit;
}
div.dt-button-collection .dt-button-active.dt-button-split {
  padding-right: 0;
}
div.dt-button-collection .dt-button-active.dt-button-split:after {
  display: none;
}
div.dt-button-collection .dt-button-active.dt-button-split > *:first-child {
  padding-right: 3em;
}
div.dt-button-collection
  .dt-button-active.dt-button-split
  > *:first-child:after {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 1em;
  display: inline-block;
  content: "✓";
  color: inherit;
}
div.dt-button-collection .dt-button-active-a a {
  padding-right: 3em;
}
div.dt-button-collection .dt-button-active-a a:after {
  position: absolute;
  right: 1em;
  display: inline-block;
  content: "✓";
  color: inherit;
}
div.dt-button-collection span.dt-button-spacer {
  width: 100%;
  font-size: 0.9em;
  text-align: center;
  margin: 0.5em 0;
}
div.dt-button-collection span.dt-button-spacer:empty {
  height: 0;
  width: 100%;
}
div.dt-button-collection span.dt-button-spacer.bar {
  border-left: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 0;
}
@media print {
  table.dataTable tr > * {
    box-shadow: none !important;
  }
}
html.dark div.dt-button-info {
  background-color: var(--dt-html-background);
  border: 1px solid rgba(255, 255, 255, 0.15);
}
div.dt-buttons > .dt-button,
div.dt-buttons > div.dt-button-split .dt-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-left: 0.167em;
  margin-right: 0.167em;
  margin-bottom: 0.333em;
  padding: 0.5em 1em;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.88em;
  line-height: 1.6em;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to bottom,
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(230, 230, 230, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
  text-overflow: ellipsis;
}
div.dt-buttons > .dt-button:first-child,
div.dt-buttons > div.dt-button-split .dt-button:first-child {
  margin-left: 0;
}
div.dt-buttons > .dt-button.disabled,
div.dt-buttons > div.dt-button-split .dt-button.disabled {
  cursor: default;
  opacity: 0.4;
}
div.dt-buttons > .dt-button.dt-button-active:not(.disabled),
div.dt-buttons
  > div.dt-button-split
  .dt-button.dt-button-active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to bottom,
    rgba(179, 179, 179, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(179, 179, 179, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
  box-shadow: inset 1px 1px 3px #999;
}
div.dt-buttons
  > .dt-button.dt-button-active:not(.disabled):hover:not(.disabled),
div.dt-buttons
  > div.dt-button-split
  .dt-button.dt-button-active:not(.disabled):hover:not(.disabled) {
  box-shadow: inset 1px 1px 3px #999;
  background-color: rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to bottom,
    rgba(128, 128, 128, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(128, 128, 128, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
}
div.dt-buttons > .dt-button:hover,
div.dt-buttons > div.dt-button-split .dt-button:hover {
  text-decoration: none;
}
div.dt-buttons > .dt-button:hover:not(.disabled),
div.dt-buttons > div.dt-button-split .dt-button:hover:not(.disabled) {
  border: 1px solid #666;
  background-color: rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to bottom,
    rgba(153, 153, 153, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr="rgba(153, 153, 153, 0.1)", EndColorStr="rgba(0, 0, 0, 0.1)");
}
div.dt-buttons > .dt-button:focus:not(.disabled),
div.dt-buttons > div.dt-button-split .dt-button:focus:not(.disabled) {
  outline: 2px solid rgb(53, 132, 228);
}
div.dt-buttons > .dt-button embed,
div.dt-buttons > div.dt-button-split .dt-button embed {
  outline: none;
}
div.dt-buttons > div.dt-button-split .dt-button:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
div.dt-buttons > div.dt-button-split .dt-button:first-child:hover {
  border-right: 1px solid #666;
}
div.dt-buttons > div.dt-button-split .dt-button:last-child {
  border-left: 1px solid transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
div.dt-buttons > div.dt-button-split .dt-button:last-child:hover {
  border-left: 1px solid #666;
}
div.dt-buttons span.dt-button-down-arrow {
  position: relative;
  top: -2px;
  font-size: 10px;
  padding-left: 10px;
  line-height: 1em;
  opacity: 0.6;
}
div.dt-buttons div.dt-button-split {
  display: inline-block;
}
div.dt-buttons div.dt-button-split .dt-button:first-child {
  margin-right: 0;
}
div.dt-buttons div.dt-button-split .dt-button:last-child {
  margin-left: -1px;
  padding-left: 0.75em;
  padding-right: 0.75em;
  z-index: 2;
}
div.dt-buttons div.dt-button-split .dt-button:last-child span {
  padding-left: 0;
}
div.dt-button-collection {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0.75em 0;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: white;
  overflow: hidden;
  z-index: 2002;
  border-radius: 5px;
  box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}
div.dt-button-collection .dt-button {
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  float: none;
  background: none;
  margin: 0;
  padding: 0.5em 1em;
  border: none;
  text-align: left;
  cursor: pointer;
  color: inherit;
}
div.dt-button-collection .dt-button.dt-button-active {
  background: none;
  box-shadow: none;
}
div.dt-button-collection .dt-button.disabled {
  cursor: default;
  opacity: 0.4;
}
div.dt-button-collection .dt-button:hover:not(.disabled) {
  border: none;
  background: rgba(153, 153, 153, 0.1);
  box-shadow: none;
}
div.dt-button-collection div.dt-button-split {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
}
div.dt-button-collection div.dt-button-split button.dt-button {
  margin: 0;
  display: inline-block;
  width: 0;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 50px;
}
div.dt-button-collection div.dt-button-split button.dt-button-split-drop {
  min-width: 33px;
  flex: 0;
}
div.dt-button-collection.fixed .dt-button {
  border-radius: 0.25em;
  background: rgba(255, 255, 255, 0.1);
}
div.dt-button-collection.fixed {
  position: fixed;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 5px;
  background-color: white;
  padding: 0.5em;
}
div.dt-button-collection.fixed.two-column {
  margin-left: -200px;
}
div.dt-button-collection.fixed.three-column {
  margin-left: -225px;
}
div.dt-button-collection.fixed.four-column {
  margin-left: -300px;
}
div.dt-button-collection.fixed.columns {
  margin-left: -409px;
}
@media screen and (max-width: 1024px) {
  div.dt-button-collection.fixed.columns {
    margin-left: -308px;
  }
}
@media screen and (max-width: 640px) {
  div.dt-button-collection.fixed.columns {
    margin-left: -203px;
  }
}
@media screen and (max-width: 460px) {
  div.dt-button-collection.fixed.columns {
    margin-left: -100px;
  }
}
div.dt-button-collection.fixed > :last-child {
  max-height: 100vh;
  overflow: auto;
}
div.dt-button-collection.two-column > :last-child,
div.dt-button-collection.three-column > :last-child,
div.dt-button-collection.four-column > :last-child {
  display: block !important;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px;
}
div.dt-button-collection.two-column > :last-child > *,
div.dt-button-collection.three-column > :last-child > *,
div.dt-button-collection.four-column > :last-child > * {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
div.dt-button-collection.two-column {
  width: 400px;
}
div.dt-button-collection.two-column > :last-child {
  padding-bottom: 1px;
  column-count: 2;
}
div.dt-button-collection.three-column {
  width: 450px;
}
div.dt-button-collection.three-column > :last-child {
  padding-bottom: 1px;
  column-count: 3;
}
div.dt-button-collection.four-column {
  width: 600px;
}
div.dt-button-collection.four-column > :last-child {
  padding-bottom: 1px;
  column-count: 4;
}
div.dt-button-collection .dt-button {
  border-radius: 0;
}
div.dt-button-collection.columns {
  width: auto;
}
div.dt-button-collection.columns > :last-child {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  width: 818px;
  padding-bottom: 1px;
}
div.dt-button-collection.columns > :last-child .dt-button {
  min-width: 200px;
  flex: 0 1;
  margin: 0;
}
div.dt-button-collection.columns.dtb-b3 > :last-child,
div.dt-button-collection.columns.dtb-b2 > :last-child,
div.dt-button-collection.columns.dtb-b1 > :last-child {
  justify-content: space-between;
}
div.dt-button-collection.columns.dtb-b3 .dt-button {
  flex: 1 1 32%;
}
div.dt-button-collection.columns.dtb-b2 .dt-button {
  flex: 1 1 48%;
}
div.dt-button-collection.columns.dtb-b1 .dt-button {
  flex: 1 1 100%;
}
@media screen and (max-width: 1024px) {
  div.dt-button-collection.columns > :last-child {
    width: 612px;
  }
}
@media screen and (max-width: 640px) {
  div.dt-button-collection.columns > :last-child {
    width: 406px;
  }
  div.dt-button-collection.columns.dtb-b3 .dt-button {
    flex: 0 1 32%;
  }
}
@media screen and (max-width: 460px) {
  div.dt-button-collection.columns > :last-child {
    width: 200px;
  }
}
div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  background: radial-gradient(
    ellipse farthest-corner at center,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  z-index: 2001;
}
.dt-button.processing {
  color: rgba(0, 0, 0, 0.2);
}
.dt-button.processing:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  box-sizing: border-box;
  display: block;
  content: " ";
  border: 2px solid rgb(40, 40, 40);
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: dtb-spinner 1500ms infinite linear;
  -o-animation: dtb-spinner 1500ms infinite linear;
  -ms-animation: dtb-spinner 1500ms infinite linear;
  -webkit-animation: dtb-spinner 1500ms infinite linear;
  -moz-animation: dtb-spinner 1500ms infinite linear;
}
@media screen and (max-width: 640px) {
  div.dt-buttons {
    float: none !important;
    text-align: center;
  }
}
html.dark div.dt-buttons > .dt-button,
html.dark div.dt-buttons > div.dt-button-split .dt-button {
  border: 1px solid rgb(89, 91, 94);
  background: rgba(255, 255, 255, 0.15);
}
html.dark div.dt-buttons > .dt-button.dt-button-active:not(.disabled),
html.dark
  div.dt-buttons
  > div.dt-button-split
  .dt-button.dt-button-active:not(.disabled) {
  background: rgba(179, 179, 179, 0.15);
  box-shadow: inset 1px 1px 2px black;
}
html.dark
  div.dt-buttons
  > .dt-button.dt-button-active:not(.disabled):hover:not(.disabled),
html.dark
  div.dt-buttons
  > div.dt-button-split
  .dt-button.dt-button-active:not(.disabled):hover:not(.disabled) {
  background: rgba(128, 128, 128, 0.15);
  box-shadow: inset 1px 1px 3px black;
}
html.dark div.dt-buttons > .dt-button:hover:not(.disabled),
html.dark div.dt-buttons > div.dt-button-split .dt-button:hover:not(.disabled) {
  background: rgba(179, 179, 179, 0.15);
}
html.dark div.dt-buttons > .dt-button:focus:not(.disabled),
html.dark div.dt-buttons > div.dt-button-split .dt-button:focus:not(.disabled) {
  outline: 2px solid rgb(110, 168, 254);
}
html.dark div.dt-buttons > div.dt-button-split .dt-button:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
html.dark div.dt-buttons > div.dt-button-split .dt-button:first-child:hover {
  border-right: 1px solid rgb(89, 91, 94);
}
html.dark div.dt-buttons > div.dt-button-split .dt-button:last-child:hover {
  border-left: 1px solid rgb(89, 91, 94);
}
html.dark div.dt-button-collection {
  border: 1px solid rgba(255, 255, 255, 0.15);
  background-color: rgb(33, 37, 41);
  box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.8);
}

div.dt-button-collection {
  overflow: visible !important;
  z-index: 2002 !important;
}
div.dt-button-collection div.dtsb-searchBuilder {
  padding-left: 1em !important;
  padding-right: 1em !important;
}
div.dt-button-collection.dtb-collection-closeable div.dtsb-titleRow {
  padding-right: 40px;
}
.dtsb-greyscale {
  border: 1px solid #cecece !important;
}
div.dtsb-logicContainer .dtsb-greyscale {
  border: none !important;
}
div.dtsb-searchBuilder {
  justify-content: space-evenly;
  cursor: default;
  margin-bottom: 1em;
  text-align: left;
}
div.dtsb-searchBuilder button.dtsb-button,
div.dtsb-searchBuilder select {
  font-size: 1em;
}
div.dtsb-searchBuilder div.dtsb-titleRow {
  justify-content: space-evenly;
  margin-bottom: 0.5em;
}
div.dtsb-searchBuilder div.dtsb-titleRow div.dtsb-title {
  display: inline-block;
  padding-top: 14px;
}
div.dtsb-searchBuilder div.dtsb-titleRow div.dtsb-title:empty {
  display: inline;
}
div.dtsb-searchBuilder div.dtsb-titleRow button.dtsb-clearAll {
  float: right;
  margin-bottom: 0.8em;
}
div.dtsb-searchBuilder div.dtsb-vertical .dtsb-value,
div.dtsb-searchBuilder div.dtsb-vertical .dtsb-data,
div.dtsb-searchBuilder div.dtsb-vertical .dtsb-condition {
  display: block;
}
div.dtsb-searchBuilder div.dtsb-group {
  position: relative;
  clear: both;
  margin-bottom: 0.8em;
}
div.dtsb-searchBuilder div.dtsb-group button.dtsb-search {
  float: right;
}
div.dtsb-searchBuilder div.dtsb-group button.dtsb-clearGroup {
  margin: 2px;
  text-align: center;
  padding: 0;
}
div.dtsb-searchBuilder div.dtsb-group div.dtsb-logicContainer {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  margin-top: 0.8em;
  margin-right: 0.8em;
}
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria {
  margin-bottom: 0.8em;
  display: flex;
  justify-content: start;
  flex-flow: row wrap;
}
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-dropDown,
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria input.dtsb-input {
  padding: 0.4em;
  margin-right: 0.8em;
  min-width: 5em;
  max-width: 20em;
  color: inherit;
  font-size: 1em;
}
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  select.dtsb-dropDown
  option.dtsb-notItalic,
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  input.dtsb-input
  option.dtsb-notItalic {
  font-style: normal;
}
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-italic {
  font-style: italic;
}
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria div.dtsb-inputCont {
  flex: 1;
  white-space: nowrap;
}
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-inputCont
  span.dtsb-joiner {
  margin-right: 0.8em;
}
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-inputCont
  input.dtsb-value {
  width: 33%;
}
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-inputCont
  select,
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-inputCont
  input {
  height: 100%;
  box-sizing: border-box;
}
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-buttonContainer {
  margin-left: auto;
  display: inline-block;
}
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-buttonContainer
  button.dtsb-delete,
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-buttonContainer
  button.dtsb-right,
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-buttonContainer
  button.dtsb-left {
  margin-right: 0.8em;
}
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-buttonContainer
  button.dtsb-delete:last-child,
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-buttonContainer
  button.dtsb-right:last-child,
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-criteria
  div.dtsb-buttonContainer
  button.dtsb-left:last-child {
  margin-right: 0;
}
@media screen and (max-width: 550px) {
  div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria {
    display: flex;
    flex-flow: none;
    flex-direction: column;
    justify-content: start;
    padding-right: calc(35px + 0.8em);
    margin-bottom: 0px;
  }
  div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria:not(:first-child),
  div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria:not(:nth-child(2)),
  div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria:not(:last-child) {
    padding-top: 0.8em;
  }
  div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria:first-child,
  div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria:nth-child(2),
  div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria:last-child {
    padding-top: 0em;
  }
  div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-dropDown,
  div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria input.dtsb-input {
    max-width: none;
    width: 100%;
    margin-bottom: 0.8em;
    margin-right: 0.8em;
  }
  div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria div.dtsb-inputCont {
    margin-right: 0.8em;
  }
  div.dtsb-searchBuilder
    div.dtsb-group
    div.dtsb-criteria
    div.dtsb-buttonContainer {
    position: absolute;
    width: 35px;
    display: flex;
    flex-wrap: wrap-reverse;
    right: 0;
  }
  div.dtsb-searchBuilder
    div.dtsb-group
    div.dtsb-criteria
    div.dtsb-buttonContainer
    button {
    margin-right: 0px !important;
  }
}
div.dtsb-searchBuilder button,
div.dtsb-searchBuilder select.dtsb-dropDown,
div.dtsb-searchBuilder input {
  background-color: #f9f9f9;
}
div.dtsb-searchBuilder button.dtsb-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5em 1em;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.88em;
  line-height: 1.6em;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to bottom,
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  outline: none;
  text-overflow: ellipsis;
}
div.dtsb-searchBuilder button.dtsb-button:hover {
  background-color: #cecece;
  cursor: pointer;
}
div.dtsb-searchBuilder div.dtsb-logicContainer {
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to right,
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
}
div.dtsb-searchBuilder div.dtsb-logicContainer button {
  border: 1px solid transparent;
  background: transparent;
}
div.dtsb-searchBuilder button.dtsb-clearGroup {
  min-width: 2em;
  padding: 0;
}
div.dtsb-searchBuilder button.dtsb-iptbtn {
  min-width: 100px;
  text-align: left;
}
div.dtsb-searchBuilder div.dtsb-group div.dtsb-logicContainer {
  border: 1px solid;
  border-color: #cecece;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-logicContainer
  button.dtsb-logic {
  border: none;
  border-radius: 0px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 3em;
  margin: 0px;
}
div.dtsb-searchBuilder
  div.dtsb-group
  div.dtsb-logicContainer
  button.dtsb-clearGroup {
  border: none;
  border-radius: 0px;
  width: 2em;
  margin: 0px;
}
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-dropDown,
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria input.dtsb-input {
  border: 1px solid;
  border-radius: 3px;
}
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-condition {
  border-color: #48b13c;
}
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-data {
  border-color: #e70f00;
}
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria select.dtsb-value,
div.dtsb-searchBuilder div.dtsb-group div.dtsb-criteria input.dtsb-value {
  border-color: #0069ba;
}
html.dark div.dtsb-searchBuilder button.dtsb-button,
html.dark div.dtsb-searchBuilder select.dtsb-dropDown,
html.dark div.dtsb-searchBuilder input.dtsb-input {
  background-color: rgb(66, 69, 73) !important;
  color-scheme: dark;
}
html.dark div.dtsb-searchBuilder button.dtsb-button {
  border: 1px solid rgb(89, 91, 94);
  background: rgba(255, 255, 255, 0.15);
}
html.dark div.dtsb-searchBuilder button.dtsb-button:hover {
  background: rgba(179, 179, 179, 0.15);
}
html.dark div.dtsb-searchBuilder div.dtsb-logicContainer {
  border: 1px solid rgb(89, 91, 94);
}
html.dark div.dtsb-searchBuilder .dtsb-greyscale {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

div.dt-datetime {
  position: absolute;
  background-color: white;
  z-index: 2050;
  border: 1px solid #ccc;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
  padding: 6px 20px;
  width: 275px;
  border-radius: 5px;
}
div.dt-datetime.inline {
  position: relative;
  box-shadow: none;
}
div.dt-datetime div.dt-datetime-title {
  text-align: center;
  padding: 5px 0px 3px;
}
div.dt-datetime div.dt-datetime-buttons {
  text-align: center;
}
div.dt-datetime div.dt-datetime-buttons a {
  display: inline-block;
  padding: 0 0.5em 0.5em 0.5em;
  margin: 0;
  font-size: 0.9em;
}
div.dt-datetime div.dt-datetime-buttons a:hover {
  text-decoration: underline;
}
div.dt-datetime table {
  border-spacing: 0;
  margin: 12px 0;
  width: 100%;
}
div.dt-datetime table.dt-datetime-table-nospace {
  margin-top: -12px;
}
div.dt-datetime table th {
  font-size: 0.8em;
  color: #777;
  font-weight: normal;
  width: 14.285714286%;
  padding: 0 0 4px 0;
  text-align: center;
}
div.dt-datetime table td {
  font-size: 0.9em;
  color: #444;
  padding: 0;
}
div.dt-datetime table td.selectable {
  text-align: center;
  background: #f5f5f5;
}
div.dt-datetime table td.selectable.disabled {
  color: #aaa;
  background: white;
}
div.dt-datetime table td.selectable.disabled button:hover {
  color: #aaa;
  background: white;
}
div.dt-datetime table td.selectable.now {
  background-color: #ddd;
}
div.dt-datetime table td.selectable.now button {
  font-weight: bold;
}
div.dt-datetime table td.selectable.selected button {
  background: #4e6ca3;
  color: white;
  border-radius: 2px;
}
div.dt-datetime table td.selectable button:hover {
  background: #ff8000;
  color: white;
  border-radius: 2px;
}
div.dt-datetime table td.dt-datetime-week {
  font-size: 0.7em;
}
div.dt-datetime table button {
  width: 100%;
  box-sizing: border-box;
  border: none;
  background: transparent;
  font-size: inherit;
  color: inherit;
  text-align: center;
  padding: 4px 0;
  cursor: pointer;
  margin: 0;
}
div.dt-datetime table button span {
  display: inline-block;
  min-width: 14px;
  text-align: right;
}
div.dt-datetime table.weekNumber th {
  width: 12.5%;
}
div.dt-datetime div.dt-datetime-calendar table {
  margin-top: 0;
}
div.dt-datetime div.dt-datetime-label {
  position: relative;
  display: inline-block;
  height: 30px;
  padding: 5px 6px;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
}
div.dt-datetime div.dt-datetime-label:hover {
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #f5f5f5;
}
div.dt-datetime div.dt-datetime-label select {
  position: absolute;
  top: 6px;
  left: 0;
  cursor: pointer;
  opacity: 0;
}
div.dt-datetime.horizontal {
  width: 550px;
}
div.dt-datetime.horizontal div.dt-datetime-date,
div.dt-datetime.horizontal div.dt-datetime-time {
  width: 48%;
}
div.dt-datetime.horizontal div.dt-datetime-time {
  margin-left: 4%;
}
div.dt-datetime div.dt-datetime-date {
  position: relative;
  float: left;
  width: 100%;
}
div.dt-datetime div.dt-datetime-time {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
}
div.dt-datetime div.dt-datetime-time > span {
  vertical-align: middle;
}
div.dt-datetime div.dt-datetime-time th {
  text-align: left;
}
div.dt-datetime div.dt-datetime-time div.dt-datetime-timeblock {
  display: inline-block;
  vertical-align: middle;
}
div.dt-datetime div.dt-datetime-iconLeft,
div.dt-datetime div.dt-datetime-iconRight {
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.3;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid transparent;
}
div.dt-datetime div.dt-datetime-iconLeft:hover,
div.dt-datetime div.dt-datetime-iconRight:hover {
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #f0f0f0;
  opacity: 0.6;
}
div.dt-datetime div.dt-datetime-iconLeft button,
div.dt-datetime div.dt-datetime-iconRight button {
  border: none;
  background: transparent;
  text-indent: 30px;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
div.dt-datetime div.dt-datetime-iconLeft {
  position: absolute;
  top: 5px;
  left: 5px;
}
div.dt-datetime div.dt-datetime-iconLeft button {
  position: relative;
  z-index: 1;
}
div.dt-datetime div.dt-datetime-iconLeft:after {
  position: absolute;
  top: 7px;
  left: 10px;
  display: block;
  content: "";
  border-top: 7px solid transparent;
  border-right: 7px solid black;
  border-bottom: 7px solid transparent;
}
div.dt-datetime div.dt-datetime-iconRight {
  position: absolute;
  top: 5px;
  right: 5px;
}
div.dt-datetime div.dt-datetime-iconRight button {
  position: relative;
  z-index: 1;
}
div.dt-datetime div.dt-datetime-iconRight:after {
  position: absolute;
  top: 7px;
  left: 12px;
  display: block;
  content: "";
  border-top: 7px solid transparent;
  border-left: 7px solid black;
  border-bottom: 7px solid transparent;
}

div.dt-datetime-error {
  clear: both;
  padding: 0 1em;
  max-width: 240px;
  font-size: 11px;
  line-height: 1.25em;
  text-align: center;
  color: #b11f1f;
}

html.dark input.dt-datetime {
  color-scheme: dark;
}
html.dark div.dt-datetime {
  border: 1px solid #595b5e;
  background-color: #212529;
  box-shadow: 3px 4px 10px 1px rgba(0, 0, 0, 0.8);
}
html.dark div.dt-datetime table th {
  color: #ccc;
}
html.dark div.dt-datetime table td {
  color: #eee;
}
html.dark div.dt-datetime table td.selectable {
  background: #373c41;
}
html.dark div.dt-datetime table td.selectable.disabled {
  color: #aaa;
  background: #171b1f;
}
html.dark div.dt-datetime table td.selectable.disabled button:hover {
  color: #aaa;
  background: #171b1f;
}
html.dark div.dt-datetime table td.selectable.now {
  background: #4b5055;
}
html.dark div.dt-datetime table td.selectable.selected button {
  background: #6ea8fe;
  color: black;
}
html.dark div.dt-datetime table td.selectable button:hover {
  background: #ff8000;
  color: black;
}
html.dark div.dt-datetime div.dt-datetime-label:hover {
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 0.1);
}
html.dark div.dt-datetime div.dt-datetime-iconLeft:hover,
html.dark div.dt-datetime div.dt-datetime-iconRight:hover,
html.dark div.dt-datetime div.dt-datetime-iconUp:hover,
html.dark div.dt-datetime div.dt-datetime-iconDown:hover {
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 0.1);
}
html.dark div.dt-datetime div.dt-datetime-iconLeft:after {
  border-right-color: white;
}
html.dark div.dt-datetime div.dt-datetime-iconRight:after {
  border-left-color: white;
}
html.dark div.dt-datetime select {
  color-scheme: dark;
}
html.dark div.dt-datetime-error {
  color: #b11f1f;
}
