.home-content {
  text-align: center;
}

.ciras-nav {
  padding: 20px;
  min-width: 225px;
  margin: 20px;
}

.data-findings {
  display: flex;
  flex-wrap: wrap;
}

.card {
  margin: 10px;
  flex: 1;
}

.fish-tile {
  flex: 1;
}

.fish-tile span {
  font-size: 2em;
}

.fish-tile svg {
  vertical-align: sub;
}

.fish-info {
  display: flex;
  flex-wrap: wrap;
}

.sd-element--nested-with-borders {
  border: none !important;
}

.sd-body.sd-body--responsive {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sd-panel__content {
  padding-top: 10px !important;
}

.sd-element[data-name="ColumnFilters"] thead span {
  display: none;
}
