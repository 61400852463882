.global-header.compact .alert-banner {
  opacity: inherit;
  position: relative;
  width: inherit;
  height: inherit;
}

.alert-banner .container {
  text-align: center;
}

.main-navigation.dropdown .sub-nav {
  min-width: 350px;
}

.language-settings .skiptranslate {
  text-align: center;
}

@media (max-width: 767px) {
  .main-navigation:not(.collapse):not(.collapsing):not(.show) {
    display: none;
  }
}
